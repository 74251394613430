<template>
    <div class="pt-14 bg-gradient-to-br from-blue-100 via-white to-blue-100 min-h-screen">
        <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div class="flex flex-col items-start max-w-4xl mx-auto">
                <h1 class="font-bold text-5xl pb-5 text-blue-900">NEMAD</h1>
                <h2 class="font-semibold text-2xl pb-5 text-blue-700">North East Materials Database</h2>
                <p class="text-left text-lg text-gray-700 mb-8">
                    Powered by advanced large language models, our project introduces a comprehensive materials database aimed at accelerating the discovery of new materials. This AI-driven platform offers open web-based access to vast collections of information on various materials, including magnetic and thermoelectric properties, all extracted and synthesized from scientific literature. It serves as a pivotal resource for researchers and innovators seeking to explore and design novel materials by providing direct access to curated data derived from the forefront of AI technology.
                </p>
                
                <div class="grid grid-cols-1 md:grid-cols-3 gap-6 w-full mb-8">
                    <div class="bg-white p-6 rounded-lg shadow-md border-2 border-blue-200 hover:border-blue-400 transition duration-300">
                        <h3 class="text-xl font-bold text-blue-900 mb-2">Magnetic Materials Database</h3>
                        <p class="text-gray-600 mb-4">Explore our database of magnetic materials and their properties.</p>
                        <div class="flex flex-col gap-3">
                            <div class="flex justify-between items-center">
                                <span class="text-blue-800 font-semibold">
                                    Count: {{ formatNumber(materials_number.magnetic) }}
                                </span>
                                <button @click="navigateToDatabase('magnetic')" class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Explore
                                </button>
                            </div>
                            <button @click="navigateToRandomSample('magnetic')" class="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                                See Random Magnetic Material
                            </button>
                        </div>
                    </div>
                    
                    <div class="bg-white p-6 rounded-lg shadow-md border-2 border-indigo-200 hover:border-indigo-400 transition duration-300">
                        <h3 class="text-xl font-bold text-indigo-900 mb-2">Thermoelectric Materials Databse</h3>
                        <p class="text-gray-600 mb-4">Discover thermoelectric materials and their characteristics.</p>
                        <div class="flex flex-col gap-3">
                            <div class="flex justify-between items-center">
                                <span class="text-indigo-800 font-semibold">
                                    Count: {{ formatNumber(materials_number.thermoelectric) }}
                                </span>
                                <button @click="navigateToDatabase('thermoelectric')" class="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                                    Explore
                                </button>
                            </div>
                            <button @click="navigateToRandomSample('thermoelectric')" class="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">
                                See Random Thermoelectric Material
                            </button>
                        </div>
                    </div>
                    
                    <div class="bg-white p-6 rounded-lg shadow-md border-2 border-purple-200 hover:border-purple-400 transition duration-300">
                        <h3 class="text-xl font-bold text-purple-900 mb-2">Superconductor Materials Database</h3>
                        <p class="text-gray-600 mb-4">Explore our database of superconductor materials and their properties.</p>
                        <div class="flex flex-col gap-3">
                            <div class="flex justify-between items-center">
                                <span class="text-purple-800 font-semibold">
                                    Count: {{ formatNumber(materials_number.superconductor) }}
                                </span>
                                <button @click="navigateToDatabase('superconductor')" class="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
                                    Explore
                                </button>
                            </div>
                            <button @click="navigateToRandomSample('superconductor')" class="w-full bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded">
                                See Random Superconductor Material
                            </button>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex flex-wrap gap-4 mb-8">
                    <button @click="onClickSignIn" class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                        Register or Login
                    </button>
                </div> -->

                <!-- 添加版权信息 -->
                <div class="w-full text-center text-gray-600 text-sm mt-8">
                    <p>© {{ currentYear }} NEMAD - North East Materials Database. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useLogto } from "@logto/vue";
import { redirectUrl } from '../consts/logto';
export default {
    name: 'HomePage',
    components: {
    },
    data() {
      return {
        materials_number: {
          magnetic: 0,
          thermoelectric: 0,
          superconductor: 0
        },
        currentYear: new Date().getFullYear(),
      };
    },
    mounted() {
        this.fetchMaterialsNum();
    },
    setup() {
        const { signIn } = useLogto();
        const onClickSignIn = () => signIn(redirectUrl);
        return { onClickSignIn };
    },
    methods: {
        navigateToDatabase(type) {
            this.$router.push({
                path: '/search',
                query: { type: type }
            });
        },
        navigateToRandomSample(type) {
            this.$router.push(`/randomsample/${type}`);
        },
        async fetchMaterialsNum() {
            try {
                const response = await fetch('/backend/materials_count');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const data = await response.json();
                this.materials_number = {
                    magnetic: Number(data.magnetic),
                    thermoelectric: Number(data.thermoelectric),
                    superconductor: Number(data.superconductor)
                };
            } catch (error) {
                console.error('Error fetching materials numbers:', error);
                this.materials_number = {
                    magnetic: 0,
                    thermoelectric: 0,
                    superconductor: 0
                };
            }
        },
        formatNumber(number) {
            return number.toLocaleString();
        },
    },
}
</script>
